<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="passwordForm">
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Ancien Mot de Passe"
              label-for="account-old-password"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|min:8"
                name="Ancien Mot de Passe"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="passwordValueOld"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    placeholder="Ancien Mot de Passe"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-for="(error, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="Nouveau Mot de Passe"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|min:8"
                name="Nouveau Mot de Passe"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="newPasswordValue"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    placeholder="Nouveau Mot de Passe"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-for="(error, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Confirmez votre mot de passe"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|min:8|confirmed:Nouveau Mot de Passe"
                name="Confirmation Mot de Passe"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="RetypePassword"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    placeholder="Nouveau mot de passe"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-for="(error, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              @click.prevent="updatePassword"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
            >
              Modifier
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              Reinitialiser
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "AccountSettingPassword",
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // validations
      required,
      min,
      confirmed,

      // values
      passwordValueOld: "",
      newPasswordValue: "",
      RetypePassword: "",
      passwordFieldTypeOld: "password",
      passwordFieldTypeNew: "password",
      passwordFieldTypeRetype: "password",
    };
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld =
        this.passwordFieldTypeOld === "password" ? "text" : "password";
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew =
        this.passwordFieldTypeNew === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
    updatePassword() {
      this.$refs.passwordForm.validate()
      .then((success) => {
        if (success) {
          this.$http
            .put("auth/reset-password", {
              old_password: this.passwordValueOld,
              new_password: this.newPasswordValue,
              new_password_confirmation: this.RetypePassword,
            })
            .then((response) => {
              localStorage.clear() // disconnect user from ui
              
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data?.message,
                  icon: "AlertOctagonIcon",
                  variant: "success",
                },
              });
              this.$router.push({ name: 'login' }) // Redirect to login page
            })
            .catch(({ response }) => {
              const { data } = response;
              let errors = {};
              if (data.errors?.old_password != undefined)
                errors["Ancien Mot de Passe"] = data.errors.old_password;
              if (data.errors?.new_password != undefined)
                errors["Nouveau Mot de Passe"] = data.errors.new_password;
              if (data.error?.new_password != undefined)
                errors["Nouveau Mot de Passe"] = data.error.new_password;
              if (data.errors?.new_password_confirmation != undefined)
                errors["Confirmation Mot de Passe"] =
                  data.errors.new_password_confirmation;

              this.$refs.passwordForm.setErrors(errors);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: data.message,
                  icon: "AlertTriangleIcon",
                  variant: "error",
                },
              });
            });
        }
      });
    },
  },
};
</script>
