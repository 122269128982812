<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Général</span>
      </template>

      <account-setting-general
        v-if="hasInfos"
        :general-data="options"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Mot de Passe</span>
      </template>

      <account-setting-password />
    </b-tab>
    <!--/ change password tab -->

    <!-- notification -->
    <b-tab v-if="can('add_user', 'Utilisateurs')">
      <!-- title -->
      <template #title>
        <feather-icon
          icon="ShieldIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Contrôle Accès</span>
      </template>

      <account-setting-notification
        v-if="hasInfos"
        :notification-data="roles"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import ability from '@/libs/acl/ability'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'

export default {
  name: "AccountSetting",
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    // AccountSettingInformation,
    // AccountSettingSocial,
    AccountSettingNotification,
  },
  data() {
    return {
      options: {},
      roles: [],
    }
  },
  computed: {
    hasInfos: function() {
      return Object.keys(this.options).length > 0
    }
  },
  mounted() {
    this.getProfilInfo()
    this.getRoles()
  },
  methods: {
    async getProfilInfo() {
      const { data } = await this.$http.get('auth/me')
      const resp = data.data
      this.options = {
        name: resp.name,
        email: resp.email,
        is_active: resp.is_active,
        role: resp.role?.name
      }
    },
    async getRoles() {
      const { data } = await this.$http.get('fetch/roles')
      this.roles = data.data
    },
    can(action, resource) {
      return ability.can(action, resource)
    },
  }
}
</script>
