<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-avatar
            ref="previewEl"
            rounded
            :text="avatarText(optionsLocal.name)"
            :src="optionsLocal.avatar"
            size="90"
          />
        </b-link>
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Charger
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        /> -->
        <!--/ upload button -->

        <!-- reset -->
        <!-- <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Réinitialiser
        </b-button> -->
        <!--/ reset -->
        <!-- <b-card-text>Autorisé JPG, PNG. Taille maximale 800kB</b-card-text> -->
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <validation-observer ref="optionLocalForm">
      <b-form class="mt-2">
        <b-row>
          <b-col sm="6">
            <b-form-group label="Nom & Prénom" label-for="account-name">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Nom"
              >
                <b-form-input
                  v-model="optionsLocal.name"
                  name="name"
                  placeholder="Nom"
                />
                <small
                  v-for="(error, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="E-mail" label-for="account-e-mail">
              <validation-provider
                #default="{ errors }"
                rules="required|email"
                name="Email"
              >
                <b-form-input
                  v-model="optionsLocal.email"
                  name="email"
                  placeholder="Email"
                />

                <small
                  v-for="(error, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ error }}</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col sm="6">
            <b-form-group label="Rôle" label-for="role">
              <b-form-input
                v-model="optionsLocal.role"
                name="role"
                placeholder="Rôle"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- alert -->
          <b-col cols="12" class="mt-75" v-if="!optionsLocal.is_active">
            <b-alert show variant="warning" class="mb-50">
              <h4 class="alert-heading">
                Your email is not confirmed. Please check your inbox.
              </h4>
              <div class="alert-body">
                <b-link class="alert-link"> Resend confirmation </b-link>
              </div>
            </b-alert>
          </b-col>
          <!--/ alert -->

          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="updateOptionLocal"
            >
              Modifier
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BAvatar,
} from "bootstrap-vue";
import {
  required,
  email,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { avatarText } from "@core/utils/filter";
import { ref } from "@vue/composition-api";

export default {
  name: "AccountSettingGeneral",
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BAvatar,
    // BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    // BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // validations
      required,
      email,

      // values
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      avatarText,
    };
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    },
    updateOptionLocal() {
      const optionLocalValidated = this.$refs.optionLocalForm.validate()
      optionLocalValidated.then(success => {
        if(success) {
          return this.$store.dispatch("auth/updateInfo", this.optionsLocal);
        }
      })
      .then((response) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Mise à jour avec succès',
            icon: "AlertCircleIcon",
            variant: "success",
          },
        });
      })
      .catch(({ data }) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue',
            icon: "BellIcon",
            text: data.message,
            variant: "danger",
          },
        });
      })
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
