<template>
  <b-card>
    <b-card-header class="mb-2">
      <b-form-select
        v-model="selectedRoleId"
        :options="localOptions"
        value-field="id"
        text-field="name"
        @change="getRoleModules"
      >
        <template #first>
          <b-form-select-option :value="null" disabled
            >-- Veuillez sélectionner une rôle --</b-form-select-option
          >
        </template>
      </b-form-select>
    </b-card-header>

    <b-row
      class="ml-3 border-bottom"
      v-for="(m, index) in roleModules"
      :key="index"
    >
      <b-form-group id="abilities-form">
        <template #label>
          <b-form-checkbox
            class="pt-2"
            v-model="allSelected[m.name]"
            :aria-describedby="`abilities-${index}`"
            :aria-controls="`abilities-${index}`"
            switch
            disabled
          >
            <h6 class="section-label">{{ m.name }}</h6>
          </b-form-checkbox>
        </template>

        <template v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            :id="`abilities-${index}`"
            v-model="selectedFonctionnalities[m.name]"
            :options="m.fonctionnalities"
            value-field="key"
            text-field="name"
            :aria-describedby="ariaDescribedby"
            :name="`abilities-${index}`"
            class="mb-2"
            :aria-label="`Individual abilities ${index}`"
            @change="(e) => toggleAbility(e, m)"
            switches
            stacked
          ></b-form-checkbox-group>
        </template>
      </b-form-group>
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardText,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCard,
    BCardHeader,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormSelect,
    BFormSelectOption,
  },
  directives: {
    Ripple,
  },
  props: {
    notificationData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedRoleId: null,
      indeterminate: {},
      roleModules: [],
      allSelected: {},
      selectedFonctionnalities: {},
    };
  },
  computed: {
    localOptions: function () {
      return this.notificationData;
    },
  },
  methods: {
    getRoleModules() {
      let currModules = []; // init modules table
      const currRole = this.localOptions.find(
        (r) => r.id == this.selectedRoleId
      );

      if (currRole != undefined) {
        for (const key in currRole.modules) {
          this.selectedFonctionnalities[key] = []
          if (Object.hasOwnProperty.call(currRole.modules, key)) {
            let abilities = currRole.modules[key]
            currModules.push({
              name: key,
              fonctionnalities: abilities,
            });
            abilities.forEach(a => {
              if(a.is_active) this.selectedFonctionnalities[key].push(a.key)
            })
            // this.checkIntermadiateState(key, abilities)
          }
        }
      }
      this.roleModules = currModules;
    },
    toggleAll(checked, modName) {
      const hasMod = this.roleModules.find((m) => m.name === modName);
      if (hasMod !== undefined) {
        if (checked) {
          const abilities = hasMod.fonctionnalities.map(ability => ability.key);
          this.$set(this.selectedFonctionnalities, modName, [...abilities]);
        } else {
          this.$set(this.selectedFonctionnalities, modName, [])
        }
      }
      // this.checkIntermadiateState(modName, hasMod.fonctionnalities)
    },
    async toggleAbility(abilities, mod) {
      try {
        const modId = mod.fonctionnalities[0]?.module.id
        const { data, status } = await this.$http.put(`toggle/roles/${this.selectedRoleId}/permissions`, {module: modId, abilities: abilities})
        if(status == 200) {
          //
        }
        // this.checkIntermadiateState(mod.name, mod.fonctionnalities)
      } catch ({ response }) {
        if(response.status == 500) {
          this.getRoleModules()
        }
      }
    },
    checkIntermadiateState(modName, abilities) {
      if(this.selectedFonctionnalities[modName].length === 0) {
        this.indeterminate[modName] = false
        this.allSelected[modName] = false
      } else if(this.selectedFonctionnalities[modName].length === abilities.length) {
        this.indeterminate[modName] = false
        this.allSelected[modName] = true
      } else {
        this.indeterminate[modName] = true
        this.allSelected[modName] = false
      }
    },
  },
};
</script>
